import { TFunction } from 'i18next';
import { formatAsNumber, numberFormatNoSeparators } from '@cp-uk/common';
import { SummaryItem, Views } from '../types';
import {
    ExpenditureFormValues,
    IncomeAndExpenditureFormValues,
    IncomeAndExpenditureSelections,
    IncomeFormValues,
} from './types';

const defaultInitialValue = '0';

export const buildInitialValues = (
    selections: IncomeAndExpenditureSelections | undefined,
): IncomeAndExpenditureFormValues => {
    if (selections === undefined) {
        return {
            income: {
                salaryWages: defaultInitialValue,
                benefitsOther: defaultInitialValue,
                total: defaultInitialValue,
            },
            expenditure: {
                volkswagenFinance: defaultInitialValue,
                rentMortgage: defaultInitialValue,
                waterGasElectric: defaultInitialValue,
                councilTax: defaultInitialValue,
                childCareMaintenance: defaultInitialValue,
                landlineInternetTv: defaultInitialValue,
                mobiles: defaultInitialValue,
                carInsuranceTax: defaultInitialValue,
                fuelTravelCosts: defaultInitialValue,
                housekeeping: defaultInitialValue,
                other: defaultInitialValue,
                otherCreditors: defaultInitialValue,
                total: defaultInitialValue,
            },
            disposableIncome: {
                total: defaultInitialValue,
            },
        };
    }

    const {
        income: { salaryWages, benefitsOther, total: incomeTotal },
        expenditure: {
            volkswagenFinance,
            rentMortgage,
            waterGasElectric,
            councilTax,
            childCareMaintenance,
            landlineInternetTv,
            mobiles,
            carInsuranceTax,
            fuelTravelCosts,
            housekeeping,
            other,
            otherCreditors,
            total: expenditureTotal,
        },
        disposableIncome: { total: disposableIncomeTotal },
    } = selections;

    return {
        income: {
            salaryWages: formatAsNumber(salaryWages, numberFormatNoSeparators),
            benefitsOther: formatAsNumber(benefitsOther, numberFormatNoSeparators),
            total: formatAsNumber(incomeTotal, numberFormatNoSeparators),
        },
        expenditure: {
            volkswagenFinance: formatAsNumber(volkswagenFinance, numberFormatNoSeparators),
            rentMortgage: formatAsNumber(rentMortgage, numberFormatNoSeparators),
            waterGasElectric: formatAsNumber(waterGasElectric, numberFormatNoSeparators),
            councilTax: formatAsNumber(councilTax, numberFormatNoSeparators),
            childCareMaintenance: formatAsNumber(childCareMaintenance, numberFormatNoSeparators),
            landlineInternetTv: formatAsNumber(landlineInternetTv, numberFormatNoSeparators),
            mobiles: formatAsNumber(mobiles, numberFormatNoSeparators),
            carInsuranceTax: formatAsNumber(carInsuranceTax, numberFormatNoSeparators),
            fuelTravelCosts: formatAsNumber(fuelTravelCosts, numberFormatNoSeparators),
            housekeeping: formatAsNumber(housekeeping, numberFormatNoSeparators),
            other: formatAsNumber(other, numberFormatNoSeparators),
            otherCreditors: formatAsNumber(otherCreditors, numberFormatNoSeparators),
            total: formatAsNumber(expenditureTotal, numberFormatNoSeparators),
        },
        disposableIncome: {
            total: formatAsNumber(disposableIncomeTotal, numberFormatNoSeparators),
        },
    };
};

export const buildSelections = ({
    income: { salaryWages, benefitsOther, total: incomeTotal },
    expenditure: {
        volkswagenFinance,
        rentMortgage,
        waterGasElectric,
        councilTax,
        childCareMaintenance,
        landlineInternetTv,
        mobiles,
        carInsuranceTax,
        fuelTravelCosts,
        housekeeping,
        other,
        otherCreditors,
        total: expenditureTotal,
    },
    disposableIncome: { total: disposableIncomeTotal },
}: IncomeAndExpenditureFormValues): IncomeAndExpenditureSelections => {
    return {
        income: {
            salaryWages: Number(salaryWages),
            benefitsOther: Number(benefitsOther),
            total: Number(incomeTotal),
        },
        expenditure: {
            volkswagenFinance: Number(volkswagenFinance),
            rentMortgage: Number(rentMortgage),
            waterGasElectric: Number(waterGasElectric),
            councilTax: Number(councilTax),
            childCareMaintenance: Number(childCareMaintenance),
            landlineInternetTv: Number(landlineInternetTv),
            mobiles: Number(mobiles),
            carInsuranceTax: Number(carInsuranceTax),
            fuelTravelCosts: Number(fuelTravelCosts),
            housekeeping: Number(housekeeping),
            other: Number(other),
            otherCreditors: Number(otherCreditors),
            total: Number(expenditureTotal),
        },
        disposableIncome: {
            total: Number(disposableIncomeTotal),
        },
    };
};

export const buildSummaryItem = (
    t: TFunction,
    {
        income: { total: incomeTotal },
        expenditure: { total: expenditureTotal },
        disposableIncome: { total: disposableIncomeTotal },
    }: IncomeAndExpenditureFormValues,
): SummaryItem => {
    const incomeTotalAsNumber = Number(incomeTotal);
    const expenditureTotalAsNumber = Number(expenditureTotal);
    const disposableIncomeTotalAsNumber = Number(disposableIncomeTotal);

    return {
        view: Views.incomeAndExpenditure,
        heading: t('summaryItem.heading'),
        content: t('summaryItem.content', {
            incomeTotal: incomeTotalAsNumber,
            expenditureTotal: expenditureTotalAsNumber,
            disposableIncomeTotal: disposableIncomeTotalAsNumber,
        }),
    };
};

const entriesTotalled = (entries: [string, string][]): number => {
    return entries
        .filter(([key, _]) => key !== 'total')
        .reduce((accumulator, [_, value]) => accumulator + Number(value), 0.0);
};

export const incomeTotalled = (income: IncomeFormValues): number => {
    return entriesTotalled(Object.entries(income));
};

export const expenditureTotalled = (expenditure: ExpenditureFormValues): number => {
    return entriesTotalled(Object.entries(expenditure));
};
