export type StringValue = string | undefined;

export const isUpperCase = (value: string): boolean => {
    return value === value.toUpperCase() && value !== value.toLowerCase();
};

export const isLowerCase = (value: string): boolean => {
    return value === value.toLowerCase() && value !== value.toUpperCase();
};

export const upperCaseFirstLetter = (value: string): string => {
    if (value.length === 0 || !isLowerCase(value[0])) {
        return value;
    }

    return `${value[0].toUpperCase()}${value.substring(1)}`;
};

export const lowerCaseFirstLetter = (value: string): string => {
    if (value.length === 0 || !isUpperCase(value[0])) {
        return value;
    }

    return `${value[0].toLowerCase()}${value.substring(1)}`;
};

export const upperCaseFirstLetterOfEachWord = (value: StringValue, exceptions: string[] = []): StringValue => {
    if (value === undefined) {
        return undefined;
    }

    return value
        .toLowerCase()
        .split(' ')
        .map((word) => (!exceptions.includes(word) ? upperCaseFirstLetter(word) : word))
        .join(' ')
        .split('-')
        .map((word) => upperCaseFirstLetter(word))
        .join('-');
};

export const quote = (value: string): string => {
    return !!value ? `'${value}'` : value;
};

export const joinForSentence = (
    values: string[],
    getCommaWithSpaceFn: () => string,
    getAndWithSpacesFn: () => string,
): string => {
    const length = values.length;
    if (length === 0) {
        return '';
    }
    if (length === 1) {
        return values[0];
    }

    const nonLastValuesJoined = values.slice(0, -1).join(getCommaWithSpaceFn());
    const lastValue = values[length - 1];
    return [nonLastValuesJoined, lastValue].join(getAndWithSpacesFn());
};

export const joinTruthyValues = (separator: string, ...values: StringValue[]): string => {
    return values.filter((value) => !!value).join(separator);
};
