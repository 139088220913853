import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { Moment } from 'moment-timezone';
import { MethodsOfPayment } from '@cp-uk/common';
import {
    isInputDateSameDayOrAfter,
    isInputDateSameDayOrBefore,
    isInputDateValid,
    isInputNumberInRange,
    isInputNumberInteger
} from 'utils';
import { MethodOfPaymentValidValues } from '../validationHelpers';
import { dateOfPaymentIsMandatory } from '../helpers';
import { PayInInstalmentsFormValues } from './types';

export const validationSchema = (
    t: TFunction,
    totalArrears: number,
    minValidDate: Moment,
    maxValidDate: Moment,
): Yup.ObjectSchema<PayInInstalmentsFormValues> => {
    const minValidAmount = 1;
    const maxValidAmount = Math.trunc(totalArrears);

    return Yup.object()
        .shape<PayInInstalmentsFormValues>({
            additionalAmount: Yup.string()
                .default('')
                .required(t('additionalAmount.validation.required'))
                .test('integer', t('additionalAmount.validation.integer'), (additionalAmount) =>
                    isInputNumberInteger(additionalAmount),
                )
                .test(
                    'range',
                    t('additionalAmount.validation.range', { minValidAmount, maxValidAmount }),
                    (additionalAmount) =>
                        isInputNumberInRange(additionalAmount, minValidAmount, maxValidAmount),
                ),
            methodOfPayment: Yup.string()
                .default('')
                .required(t('request-additional-help-pay-common:methodOfPayment.validation.required'))
                .oneOf(MethodOfPaymentValidValues, t('request-additional-help-pay-common:methodOfPayment.validation.required')),
            dateOfFirstPayment: Yup.string()
                .default('')
                .defined()
                .when('methodOfPayment', (methodOfPayment: string, schema: Yup.StringSchema<string>) => {
                    if (dateOfPaymentIsMandatory(methodOfPayment as MethodsOfPayment)) {
                        return schema
                            .required(t('dateOfFirstPayment.validation.required'))
                            .test('format', t('dateOfFirstPayment.validation.format'), (dateOfFirstPayment) =>
                                isInputDateValid(dateOfFirstPayment),
                            )
                            .test(
                                'minValidDate',
                                t('dateOfFirstPayment.validation.minValidDate', { minValidDate }),
                                (dateOfFirstPayment) => isInputDateSameDayOrAfter(dateOfFirstPayment, minValidDate),
                            )
                            .test(
                                'maxValidDate',
                                t('dateOfFirstPayment.validation.maxValidDate', { maxValidDate }),
                                (dateOfFirstPayment) => isInputDateSameDayOrBefore(dateOfFirstPayment, maxValidDate),
                            );
                    } else {
                        return schema.length(0, t('dateOfFirstPayment.validation.mustBeBlank'));
                    }
                }),
        })
        .required();
};
