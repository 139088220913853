import { TFunction } from 'i18next';
import { SummaryItem, Views } from '../types';
import { BreathingSpaceSelections } from './types';

export const buildSelections = (): BreathingSpaceSelections => {
    // NOTE: There are no fields to populate
    return {};
};

export const buildSummaryItem = (t: TFunction): SummaryItem => {
    return {
        view: Views.breathingSpace,
        heading: t('summaryItem.heading'),
        content: t('summaryItem.content'),
    };
};
