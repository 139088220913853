import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@vwfs-bronson/bronson-react';
import { Notification, NotificationStatus } from '@cp-shared-8/frontend-ui';
import { textAsHtml } from 'utils';
import { currentBrand } from 'config';

export type CommissionArrangementsNotificationProps = {
    className?: string;
    testId?: string;
};

export const CommissionArrangementsNotification: React.FC<CommissionArrangementsNotificationProps> = ({
    className,
    testId,
}) => {
    const { t } = useTranslation('commission-arrangements-notification');
    const [visible, setVisible] = useState(true);

    const buttonUrl = t(`notifications.callToActionUrls.${currentBrand}.buttonUrl`);

    return (
        <Notification
            className={className}
            visible={visible}
            status={NotificationStatus.info}
            showCloseButton
            onCloseClick={(): void => setVisible(false)}
            headline={t('notifications.commission-arrangements.headline')}
            testId={testId}
            buttons={
                <Button element={'a'} href={buttonUrl} target={'_BLANK'} secondary>
                    {t('translation:editableSectionNav.findOutMore')}
                </Button>
            }
        >
            {textAsHtml(t('notifications.commission-arrangements.text'))}
        </Notification>
    );
};
