import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { isInputNumberInRange, isInputNumberInteger } from 'utils';
import {
    DisposableIncomeFormValues,
    ExpenditureFormValues,
    IncomeAndExpenditureFormValues,
    IncomeFormValues,
} from './types';

export const validationSchema = (t: TFunction): Yup.ObjectSchema<IncomeAndExpenditureFormValues> => {
    const minValidMoney = 0;
    const maxValidMoney = 999999;

    const buildSchemaForEditableMoney = (): Yup.StringSchema<string, object> => {
        return Yup.string()
            .required(t('money.validation.required'))
            .test('integer', t('money.validation.integer'), (value) => isInputNumberInteger(value))
            .test('range', t('money.validation.range'), (value) =>
                isInputNumberInRange(value, minValidMoney, maxValidMoney),
            );
    };
    const buildSchemaForTotalMoney = (): Yup.StringSchema<string, object> => {
        return Yup.string()
            .required(t('money.validation.required'))
            .test('integer', t('money.validation.integer'), (value) => isInputNumberInteger(value));
    };

    return Yup.object()
        .shape<IncomeAndExpenditureFormValues>({
            income: Yup.object()
                .shape<IncomeFormValues>({
                    salaryWages: buildSchemaForEditableMoney(),
                    benefitsOther: buildSchemaForEditableMoney(),
                    total: buildSchemaForTotalMoney(),
                })
                .required(),
            expenditure: Yup.object()
                .shape<ExpenditureFormValues>({
                    volkswagenFinance: buildSchemaForEditableMoney(),
                    rentMortgage: buildSchemaForEditableMoney(),
                    waterGasElectric: buildSchemaForEditableMoney(),
                    councilTax: buildSchemaForEditableMoney(),
                    childCareMaintenance: buildSchemaForEditableMoney(),
                    landlineInternetTv: buildSchemaForEditableMoney(),
                    mobiles: buildSchemaForEditableMoney(),
                    carInsuranceTax: buildSchemaForEditableMoney(),
                    fuelTravelCosts: buildSchemaForEditableMoney(),
                    housekeeping: buildSchemaForEditableMoney(),
                    other: buildSchemaForEditableMoney(),
                    otherCreditors: buildSchemaForEditableMoney(),
                    total: buildSchemaForTotalMoney(),
                })
                .required(),
            disposableIncome: Yup.object()
                .shape<DisposableIncomeFormValues>({
                    total: buildSchemaForTotalMoney(),
                })
                .required(),
        })
        .required();
};
