import { CPDate } from '@cp-shared-8/common-utilities';
import { ContractDescriptionBO } from '@cp-uk/common';

export enum Views {
    optionsAvailable = 'optionsAvailable',
    payInOneGo = 'payInOneGo',
    payInInstalments = 'payInInstalments',
    breathingSpace = 'breathingSpace',
    otherOptions = 'otherOptions',
    incomeAndExpenditure = 'incomeAndExpenditure',
    additionalInformation = 'additionalInformation',
    contactDetails = 'contactDetails',
    summaryAndRequest = 'summaryAndRequest',
}

export type OptionsAvailableNextViews = Extract<
    Views,
    Views.payInOneGo | Views.payInInstalments | Views.breathingSpace | Views.otherOptions
>;

export type AdditionalInformationPreviousViews = Extract<
    Views,
    Views.payInOneGo | Views.incomeAndExpenditure | Views.breathingSpace | Views.otherOptions
>;

export interface SummaryItem {
    view: Views;
    heading: string;
    content: string;
}

export type PayCommonViewProps = {
    contractDescription: ContractDescriptionBO;
    totalArrears: number;
    changeBankAccountInProgress: boolean;
    changePaymentDateInProgress: boolean;
    sortCode: string;
    accountNumber: string;
    lastBilledPaymentDate: CPDate | undefined;
    nextScheduledPaymentDate: CPDate | undefined;
    currentOptionTitle: string;
    onBack: () => void;
};
