import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Heading, Paragraph, Card, Layout, Modal } from '@vwfs-bronson/bronson-react';
import { getSimpleDataSelector, useGetSimpleApiData } from '@cp-shared-8/frontend-integration';
import {
    ContractDescriptionBO,
    FinancialSupportAndWellbeingBO,
    FinancialSupportAndWellbeingContractBO,
    isProductType,
    ProductTypes,
} from '@cp-uk/common';
import { currentBrand } from 'config';
import {
    textWithComponents,
    toBrandCase,
    toCamelCase,
    upperCaseFirstLetter,
    useAnalyticsActionTracker,
    useAnalyticsPageViewTracker,
    useWebsiteFinanceCompanyDetails,
    getTelHref,
    getMailtoHref,
} from 'utils';
import { ContractDescription } from 'components/contract-description/ContractDescription';
import { retailerDescriptionText, currentBrandAsFinanceBrands } from 'components/helpers';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import {
    linkToDashboardPage,
    linkToRequestContactPage,
    linkToRequestContactPageFinancialDifficulty,
} from 'components/link-to-pages';
import { SimpleLink } from 'components/simple-link/SimpleLink';
import {
    ExternalWebsitesNotification,
    ExternalWebsitesNotificationMode,
} from 'components/notifications/external-websites/ExternalWebsitesNotification';
import { View } from 'components/view/View';
import { fetchFinancialSupportAndWellbeing } from './FinancialSupportAndWellbeingSlice';
import { selectFinancialSupportAndWellbeing } from './FinancialSupportAndWellbeingSelector';
import { getServiceData, getTrackingId } from './helpers';
import { ContentText } from './types';

export type OrganisationPhoneNumber = {
    key: string;
    number: string;
    suffix: string | undefined;
    freephone: boolean;
};

export type IndependentOrganisation = {
    key: string;
    name: string;
    description: string;
    website: string;
    phoneNumbers: OrganisationPhoneNumber[];
};

export type ContentLink = {
    key: string;
    style: 'button' | 'simpleLink' | 'simpleDownloadLink' | 'link';
    href: string;
    testId: string;
};

export const FinancialSupportAndWellbeingContentUi: React.FC<{
    contentId: string;
    financialSupportAndWellbeing?: FinancialSupportAndWellbeingBO | undefined;
}> = ({ contentId, financialSupportAndWellbeing }) => {
    const { t } = useTranslation([
        `financial-support-and-wellbeing-${contentId}`,
        'financial-support-and-wellbeing-content',
    ]);
    const history = useHistory();
    const { shortCompanyName } = useWebsiteFinanceCompanyDetails('service');

    const [showSelectContractModal, setShowSelectContractModal] = useState<boolean>(false);
    const [selectContractModalBaseHref, setSelectContractModalBaseHref] = useState<string>('');

    const trackingId = getTrackingId(
        'financialSupportAndWellbeingContent',
        showSelectContractModal ? 'select-contract-modal' : contentId,
    );
    useAnalyticsPageViewTracker(trackingId, !!financialSupportAndWellbeing);
    const { onAction: onLinkAction } = useAnalyticsActionTracker(`${trackingId}Link`);
    const { onAction: onPreviousNextContentAction } = useAnalyticsActionTracker(`${trackingId}PreviousNextContent`);
    const { onAction: onBackAction } = useAnalyticsActionTracker(`${trackingId}Back`);

    if (financialSupportAndWellbeing === undefined) {
        return null;
    }

    if (!t('subHeading', '')) {
        return (
            <Paragraph testId={'unknownParagraph'}>
                {t('financial-support-and-wellbeing-content:paragraphs.unknown', { contentId })}
            </Paragraph>
        );
    }

    const buildLinkClick = (testId: string, href: string): (() => void) => {
        return (): void => {
            onLinkAction(testId, href);
        };
    };

    const contentIdInCamelCase = toCamelCase(contentId.replace(/-/g, '_'));

    const buildNavClick = (testId: string, key: string): (() => void) => {
        const onAction = key === 'backUrl' ? onBackAction : onPreviousNextContentAction;
        const href = t(key);

        return (): void => {
            onAction(testId, href);
            history.push(href);
        };
    };

    const buildPhoneNumberLink = (key: string, number: string, testId: string): React.ReactNode => {
        const clickTestId = !!key ? `${key}${upperCaseFirstLetter(testId)}` : testId;
        const telHref = getTelHref(number);
        return <SimpleLink href={telHref} onClick={buildLinkClick(clickTestId, telHref)} testId={testId} />;
    };

    const buildEmailAddressLink = (emailAddress: string, testId: string): React.ReactNode => {
        const mailtoHref = getMailtoHref(emailAddress);
        return <SimpleLink href={mailtoHref} onClick={buildLinkClick(testId, mailtoHref)} testId={testId} />;
    };

    const { contracts } = financialSupportAndWellbeing;

    const relevantProductTypes = t<ProductTypes[]>('relevantProductTypes', { returnObjects: true });

    const relevantContracts = contracts.filter((contract) => isProductType(contract.productType, relevantProductTypes));

    const collectionsPhoneNumber = t('translation:phoneNumbers.collections');
    const collectionsEmailAddress = t('translation:emailAddresses.collections');

    const linkToCollectionsNumber = buildPhoneNumberLink('', collectionsPhoneNumber, 'collectionsPhoneNumberLink');
    const linkToCollectionsEmail = buildEmailAddressLink(collectionsEmailAddress, 'collectionsEmailAddressLink');

    const pWithMarginBottom = <p className={'u-mb'} />;

    const addOnClick = (Component: JSX.Element): JSX.Element => {
        const onClick = buildLinkClick(Component.props['data-testid'], Component.props.to);

        return <Component.type {...Component.props} onClick={onClick} />;
    };

    const components: Record<string, React.ReactNode | undefined> = {
        linkToDashboardPage: addOnClick(linkToDashboardPage),
        linkToRequestContactPage: addOnClick(linkToRequestContactPage),
        linkToRequestContactPageFinancialDifficulty: addOnClick(linkToRequestContactPageFinancialDifficulty),
        linkToCollectionsNumber,
        linkToCollectionsEmail,
        pWithMarginBottom,
    };

    const {
        servicingPlanType,
        vehicleBrand: propsVehicleBrand,
        remainingServiceEntitlement,
        vehicleRegistration,
        financeBrand,
    } = getServiceData(contracts);

    const vehicleBrand = toBrandCase(propsVehicleBrand || '');

    const retailerDescription = retailerDescriptionText(currentBrandAsFinanceBrands());

    const remainingServiceEntitlementKey = (remainingServiceEntitlement || 0) === 1 ? 'singular' : 'plural';
    const remainingServiceEntitlementText = t(
        `financial-support-and-wellbeing-content:remainingServiceEntitlement.${remainingServiceEntitlementKey}`,
        { remainingServiceEntitlement },
    );

    const tOptions = { shortCompanyName, retailerDescription };

    const onSelectContractModalClick = (testId: string, href: string): void => {
        if (relevantContracts.length === 1) {
            const encryptedContractId = relevantContracts[0].encryptedContractId;
            const hrefWithEncryptedContractId = `${href}${encryptedContractId}`;
            onLinkAction(testId, hrefWithEncryptedContractId);
            history.push(hrefWithEncryptedContractId);
        } else {
            setShowSelectContractModal(true);
            setSelectContractModalBaseHref(href);
        }
    };

    const buildLink = (
        style: 'button' | 'simpleLink' | 'simpleDownloadLink' | 'link' | 'buttonWithSelectContractModal',
        href: string,
        testId: string,
    ): React.ReactNode | undefined => {
        switch (style) {
            case 'button':
                if (href?.startsWith('/')) {
                    return <Link to={href} data-testid={testId} onClick={buildLinkClick(testId, href)} className={'c-btn c-btn--secondary'} />;
                } else {
                    return <Button element={'a'} href={href} onClick={buildLinkClick(testId, href)} target={'_BLANK'} testId={testId} secondary />;
                }
            case 'simpleLink':
                return <SimpleLink href={href} target={'_BLANK'} testId={testId} onClick={buildLinkClick(testId, href)} />;
            case 'simpleDownloadLink':
                return <SimpleLink href={href} target={'_BLANK'} testId={testId} onClick={buildLinkClick(testId, href)} download />;
            case 'link':
                return <Link to={href} data-testid={testId} onClick={buildLinkClick(testId, href)} />;
            case 'buttonWithSelectContractModal':
                return <Button type={'button'} testId={testId} onClick={(): void => onSelectContractModalClick(testId, href)} secondary />;
            default:
                // NOTE: Leave in as testing is done for all link styles
                // eslint-disable-next-line no-alert
                alert(`Unexpected link style: ${style}`);
                return undefined;
        }
    };

    const buildContractDescription = (contract: FinancialSupportAndWellbeingContractBO): ContractDescriptionBO => {
        const { vehicleBrand, vehicleDescription, contractNumber, vehicleRegistrationNumber, productType } = contract;

        return {
            vehicleBrand,
            vehicleDescription,
            contractNumber,
            registrationNumber: vehicleRegistrationNumber,
            productType,
        };
    };

    const links = t<ContentLink[]>('links', { returnObjects: true, brand: currentBrand, financeBrand });
    links.reduce((accumulator, { key, style, href, testId }) => {
        accumulator[key] = buildLink(style, href, testId);
        return accumulator;
    }, components);

    const texts = t<ContentText[]>('texts', {
        returnObjects: true,
        brand: currentBrand,
        servicingPlanType,
        vehicleBrand,
        remainingServiceEntitlementText,
        vehicleRegistration,
        shortCompanyName,
        retailerDescription,
    });
    texts.reduce((accumulator, { key, text }) => {
        accumulator[key] = textWithComponents(undefined, text, components);
        return accumulator;
    }, components);

    const buildOrganisationWebsiteLink = (key: string, name: string, href: string): React.ReactNode => {
        return (
            <SimpleLink
                className={'u-h4 u-inline'}
                href={href}
                onClick={buildLinkClick(`${key}WebsiteLink`, href)}
                target={'_BLANK'}
                testId={'websiteLink'}
            >
                {name}
            </SimpleLink>
        );
    };

    const getOrganisationPhoneNumberLabel = (suffix: string | undefined, freephone: boolean): string => {
        const phoneFragment = freephone ? 'freephone' : 'phone';
        const baseLabel = t(`financial-support-and-wellbeing-content:labels.${phoneFragment}`);
        const suffixLabel = !!suffix ? ` (${suffix})` : '';
        return `${baseLabel}${suffixLabel}`;
    };

    const buildOrganisationPhoneNumberLink = (
        key: string,
        number: string,
        suffix: string | undefined,
    ): React.ReactNode => {
        const phoneNumberLink = buildPhoneNumberLink(key, number, `phoneNumber${suffix ?? ''}Link`);
        return textWithComponents(undefined, `<phoneNumberLink>${number}</phoneNumberLink>`, {
            phoneNumberLink,
        });
    };

    const buildOrganisationPhoneNumberLabelAndLink = (
        key: string,
        number: string,
        suffix: string | undefined,
        freephone: boolean,
    ): React.ReactNode => {
        return (
            <span>
                <span className={'u-block'}>{getOrganisationPhoneNumberLabel(suffix, freephone)}</span>
                <span className={'u-block'}>{buildOrganisationPhoneNumberLink(key, number, suffix)}</span>
            </span>
        );
    };

    const visibleOrganisations = t<string[]>('visibleOrganisations', { returnObjects: true });
    const organisations =
        visibleOrganisations.length > 0
            ? t<IndependentOrganisation[]>('financial-support-and-wellbeing-content:independentOrganisations', {
                  returnObjects: true,
              }).filter(({ key }) => visibleOrganisations.includes(key))
            : [];

    const budgetPlannerUrl = t('financial-support-and-wellbeing-content:commonUrls.moneyHelperBudgetPlanner');
    const budgetPlannerLink = (
        <SimpleLink
            href={budgetPlannerUrl}
            target={'_BLANK'}
            testId={'budgetPlannerLink'}
            onClick={buildLinkClick('budgetPlannerLink', budgetPlannerUrl)}
        />
    );

    const externalWebsitesNotificationMode = t('externalWebsitesNotification', {
        brand: currentBrand,
    }) as ExternalWebsitesNotificationMode;

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const onIgnoreClose = (): void => {};

    const onCancelSelectContractModal = (): void => {
        setShowSelectContractModal(false);
    };

    const buildSelectContractClick = (testId: string, encryptedContractId: string): (() => void) => {
        const hrefWithEncryptedContractId = `${selectContractModalBaseHref}${encryptedContractId}`;
        return (): void => {
            onLinkAction(testId, hrefWithEncryptedContractId);
            history.push(hrefWithEncryptedContractId);
        };
    };

    return (
        <section>
            <Heading level={2} testId={'subHeading'}>
                {t('subHeading')}
            </Heading>
            <View testId={`${contentIdInCamelCase}Content`}>
                {textWithComponents(t, 'content', components, tOptions)}
            </View>
            {organisations.length > 0 && (
                <Card
                    element={'section'}
                    className={'uk-organisations c-card--body-pv-none u-text-left u-mb-small'}
                    testId={`${contentIdInCamelCase}Organisations`}
                >
                    {organisations.map(({ key, name, description, website, phoneNumbers }, index) => {
                        const classBorder = index > 0 ? 'u-border-t' : '';
                        const classDescription = (phoneNumbers?.length ?? 0) > 0 ? 'u-mb-small' : 'u-mb-none';
                        return (
                            <article key={key} data-testid={`${key}Organisation`} className={`u-pv ${classBorder}`}>
                                <Paragraph className={'u-mb-small'}>
                                    {buildOrganisationWebsiteLink(key, name, website)}
                                </Paragraph>
                                <Paragraph className={classDescription} testId={'descriptionParagraph'}>
                                    {textWithComponents(undefined, description, { budgetPlannerLink })}
                                </Paragraph>
                                {phoneNumbers?.length > 0 && (
                                    <Layout>
                                        {phoneNumbers?.map(({ key: innerKey, number, suffix, freephone }) => (
                                            <Layout.Item key={innerKey} default={'1/3'} m={'1/2'} s={'1/1'}>
                                                {buildOrganisationPhoneNumberLabelAndLink(key, number, suffix, freephone)}
                                            </Layout.Item>
                                        ))}
                                    </Layout>
                                )}
                            </article>
                        );
                    })}
                </Card>
            )}
            <ExternalWebsitesNotification
                className={'u-mb-small'}
                mode={externalWebsitesNotificationMode}
            />
            <Paragraph>
                <Button
                    className={'c-btn--compact u-float-left u-mb-xsmall'}
                    type={'button'}
                    icon={'semantic-back'}
                    iconReversed
                    secondary
                    testId={'backButton'}
                    onClick={buildNavClick('backButton', 'backUrl')}
                >
                    {t('translation:editableSectionNav.back')}
                </Button>
                {!!t('next.label', '') && (
                    <Button
                        className={'c-btn--compact u-float-right u-ml-xsmall u-mb-xsmall'}
                        type={'button'}
                        small
                        icon={'semantic-forward'}
                        iconReversed
                        secondary
                        testId={'nextContentButton'}
                        onClick={buildNavClick('nextContentButton', 'next.url')}
                    >
                        {t('next.label')}
                    </Button>
                )}
                {!!t('previous.label', '') && (
                    <Button
                        className={'c-btn--compact u-float-right u-ml-xsmall u-mb-xsmall'}
                        type={'button'}
                        small
                        icon={'semantic-back'}
                        iconReversed
                        secondary
                        testId={'previousContentButton'}
                        onClick={buildNavClick('previousContentButton', 'previous.url')}
                    >
                        {t('previous.label')}
                    </Button>
                )}
            </Paragraph>
            {relevantContracts.length > 1 && (
                <Modal
                    title={t('financial-support-and-wellbeing-content:modals.selectContract.title')}
                    shown={showSelectContractModal}
                    closeAny={false}
                    hideCloseButton={true}
                    buttonCancelText={t('translation:editableSectionNav.cancel')}
                    onClose={onIgnoreClose}
                    onCancel={onCancelSelectContractModal}
                    testId={'selectContractModal'}
                >
                    <div className={'u-mb'}>
                        {relevantContracts.map((contract) => {
                            const contractDescription = buildContractDescription(contract);
                            const { encryptedContractId, contractNumber } = contract;
                            return (
                                <ContractDescription
                                    key={contractNumber}
                                    contractDescription={contractDescription}
                                    className={'u-mb-small'}
                                    testId={`contract-${contractNumber}`}
                                >
                                    <Button
                                        type={'button'}
                                        className={'c-btn--compact u-block u-mt-small'}
                                        secondary
                                        onClick={buildSelectContractClick('selectContractButton', encryptedContractId)}
                                        testId={`selectContractButton`}
                                    >
                                        {t('translation:editableSectionNav.select')}
                                    </Button>
                                </ContractDescription>
                            );
                        })}
                    </div>
                </Modal>
            )}
        </section>
    );
};

const FinancialSupportAndWellbeingContentWithHandlers = withLoadingAndNoConnectionHandler(
    FinancialSupportAndWellbeingContentUi,
);

export const FinancialSupportAndWellbeingContent: React.FC<{ contentId: string }> = ({ contentId }) => {
    const { data: financialSupportAndWellbeing, isLoading, loadingError } = useGetSimpleApiData(
        fetchFinancialSupportAndWellbeing,
        getSimpleDataSelector(selectFinancialSupportAndWellbeing),
    );

    return (
        <FinancialSupportAndWellbeingContentWithHandlers
            isLoading={isLoading}
            hasError={!!loadingError}
            contentId={contentId}
            financialSupportAndWellbeing={financialSupportAndWellbeing}
        />
    );
};
