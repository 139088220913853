import React, { Fragment } from 'react';
import { TFunction } from 'i18next';
import { CPDate } from '@cp-shared-8/common-utilities';
import { MethodsOfPayment, parseCPDateFromInput } from '@cp-uk/common';
import 'extensions';
import { getValueFromEnumKey, textWithComponents } from 'utils';
import { BankDetailsForDirectDebit } from 'components/bank-details-for-direct-debit/BankDetailsForDirectDebit';
import { RadioButtonProps } from 'components/validated-radio-buttons/ValidatedRadioButtons';
import { Options } from './options-available-view/types';

const directDebitTooltipCommonFragment = (t: TFunction): React.ReactNode => {
    return t('request-additional-help-pay-common:methodOfPayment.directDebit.tooltip');
};

const directDebitTooltipNotAvailableFragment = (t: TFunction, directDebitDisabled: boolean): React.ReactNode => {
    return directDebitDisabled ? textWithComponents(t, 'request-additional-help-pay-common:methodOfPayment.directDebit.notAvailable') : null;
};

export const getMethodOfPaymentRadioButtons = (t: TFunction, directDebitDisabled: boolean): RadioButtonProps[] => {
    return [
        {
            label: t('request-additional-help-pay-common:methodOfPayment.directDebit.label'),
            tooltip: (
                <Fragment>
                    {directDebitTooltipCommonFragment(t)} {directDebitTooltipNotAvailableFragment(t, directDebitDisabled)}
                </Fragment>
            ),
            value: 'directDebit',
            disabled: directDebitDisabled,
        },
        {
            label: t('request-additional-help-pay-common:methodOfPayment.cardPayment.label'),
            tooltip: t('request-additional-help-pay-common:methodOfPayment.cardPayment.tooltip'),
            value: 'cardPayment',
        },
        {
            label: t('request-additional-help-pay-common:methodOfPayment.bankTransfer.label'),
            tooltip: t('request-additional-help-pay-common:methodOfPayment.bankTransfer.tooltip'),
            value: 'bankTransfer',
        },
    ];
};

export const getActionNotificationContent = (t: TFunction, methodOfPayment: MethodsOfPayment, sortCode: string, accountNumber: string): React.ReactNode => {
    let content: React.ReactNode;
    if (methodOfPayment === 'directDebit') {
        content = (
            <Fragment>
                {textWithComponents(t, 'notifications.action.text.directDebit')}
                <BankDetailsForDirectDebit
                    className={'c-description-list--notification u-mb-none'}
                    sortCode={sortCode}
                    accountNumber={accountNumber}
                />
            </Fragment>
        );
    } else if (methodOfPayment === 'cardPayment') {
        content = textWithComponents(t, 'notifications.action.text.cardPayment');
    } else if (methodOfPayment === 'bankTransfer') {
        content = textWithComponents(t, 'notifications.action.text.bankTransfer');
    } else {
        content = t('unsupported.methodOfPayment', { methodOfPayment });
    }

    return content;
};

export const asType = <T,>(value: unknown): T => {
    return value as T;
};

export const getDirectDebitDisabled = (
    changeBankAccountInProgress: boolean,
    changePaymentDateInProgress: boolean,
    inBillingPeriod: boolean,
    noRegularPayments: boolean,
): boolean => {
    return changeBankAccountInProgress || changePaymentDateInProgress || inBillingPeriod || noRegularPayments;
};

export const getInitialOptionFromProp = (propInKebabCase: string | undefined): Options => {
    const value = getValueFromEnumKey(Options, propInKebabCase);

    return asType<Options>(value ?? '');
};

export const getDateOfPaymentAsCPDate = (dateOfPayment: string): CPDate | undefined => {
    return !!dateOfPayment ? parseCPDateFromInput(dateOfPayment).toMoment().toUTC().toCPDate() : undefined;
};

export const getDateOfPaymentEffective = (
    methodOfPayment: string,
    dateOfPayment: CPDate | undefined,
    nextScheduledPaymentDate: CPDate | undefined,
): CPDate => {
    return methodOfPayment === 'directDebit' ? nextScheduledPaymentDate ?? '' : dateOfPayment ?? '';
};

export const dateOfPaymentIsMandatory = (methodOfPayment: MethodsOfPayment): boolean => {
    return methodOfPayment === 'cardPayment' || methodOfPayment === 'bankTransfer';
};

export const dateOfPaymentDisabled = (methodOfPayment: MethodsOfPayment): boolean => {
    return !dateOfPaymentIsMandatory(methodOfPayment);
};
