import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, FormikProps } from 'formik';
import { Button, ButtonContainer, Fieldset, Form, Paragraph, SectionHeading } from '@vwfs-bronson/bronson-react';
import { preventSubmit } from '@cp-shared-8/frontend-ui';
import { ContractDescriptionBO } from '@cp-uk/common';
import { View } from 'components/view/View';
import { ContractDescription } from 'components/contract-description/ContractDescription';
import { ValidatedCheckboxGroup } from 'components/validated-checkbox-group/ValidatedCheckboxGroup';
import { ValidatedInput } from 'components/validated-input/ValidatedInput';
import { ArrearsBalanceParagraph } from '../arrears-balance-paragraph/ArrearsBalanceParagraph';
import { AlternativeNotification } from '../alternative-notification/AlternativeNotification';
import { SummaryItem } from '../types';
import { validationSchema } from './OtherOptionsValidation';
import { OtherOptionsFormValues, OtherOptionsSelections } from './types';
import {
    buildInitialValues,
    buildSelections,
    buildSummaryItem,
    somethingElseMoreDetailDisabled,
    somethingElseMoreDetailIsMandatory,
} from './helpers';

export type OtherOptionsViewProps = {
    contractDescription: ContractDescriptionBO;
    totalArrears: number | undefined;
    currentOptionTitle: string;
    currentSelections: OtherOptionsSelections | undefined;
    onBack: () => void;
    onContinue: (selections: OtherOptionsSelections, summaryItem: SummaryItem) => void;
};

export const OtherOptionsView: React.FC<OtherOptionsViewProps> = ({
    contractDescription,
    totalArrears,
    currentOptionTitle,
    currentSelections,
    onBack,
    onContinue,
}) => {
    const { t } = useTranslation('request-additional-help-other-options-view');

    // TODO: Analytics...

    const initialValues = buildInitialValues(currentSelections);

    const resetSomethingElseMoreDetail = ({
        setFieldValue,
        setFieldTouched,
    }: FormikProps<OtherOptionsFormValues>): void => {
        setFieldValue('somethingElseMoreDetail', '', false);
        setFieldTouched('somethingElseMoreDetail', false, false);
    };

    const onOtherOptionsChange = (
        { target: { name } }: React.ChangeEvent<HTMLInputElement>,
        formik: FormikProps<OtherOptionsFormValues>,
    ): void => {
        if (name === 'otherOptions.somethingElse') {
            resetSomethingElseMoreDetail(formik);
        }
    };

    const onBackClick = (): void => {
        // TODO: Analytics...
        onBack();
    };

    return (
        <View testId={'otherOptionsView'}>
            <SectionHeading level={2} testId={'viewHeader'}>
                {t('subHeading', { currentOptionTitle })}
            </SectionHeading>
            <ArrearsBalanceParagraph totalArrears={totalArrears} />
            <ContractDescription className={'u-mb'} contractDescription={contractDescription} />
            <Paragraph className={'u-mb-large'} testId={'introductionParagraph'}>
                {t('paragraphs.introduction')}
            </Paragraph>
            <Paragraph className={'u-mb-large'} testId={'doesNotFeelRightParagraph'}>
                {t('paragraphs.doesNotFeelRight')}
            </Paragraph>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema(t)}
                onSubmit={(values): void => {
                    const selections = buildSelections(values);

                    const summaryItem = buildSummaryItem(t, values);

                    // TODO: Analytics...
                    onContinue(selections, summaryItem);
                }}
            >
                {(formik: FormikProps<OtherOptionsFormValues>): React.ReactNode => (
                    <Form className={'uk-request-additional-help-other-options-form'} onSubmit={preventSubmit}>
                        <Fieldset>
                            <Fieldset.Row>
                                <ValidatedCheckboxGroup
                                    className={'u-indent'}
                                    label={t('otherOptions.label')}
                                    secondaryLabel={t('otherOptions.secondaryLabel')}
                                    name={'otherOptions'}
                                    testId={'otherOptions'}
                                    isMandatory={true}
                                >
                                    {Object.keys(formik.values.otherOptions).map((key) => (
                                        <ValidatedCheckboxGroup.Checkbox
                                            key={key}
                                            className={'u-mb-xsmall'}
                                            label={t(`otherOptions.${key}.label`)}
                                            groupName={'otherOptions'}
                                            name={`otherOptions.${key}`}
                                            testId={`otherOptions.${key}`}
                                            handleChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                                                onOtherOptionsChange(e, formik)
                                            }
                                        />
                                    ))}
                                </ValidatedCheckboxGroup>
                                <div className={'u-indent'}>
                                    <ValidatedInput
                                        label={t('somethingElseMoreDetail.label')}
                                        name={'somethingElseMoreDetail'}
                                        testId={'somethingElseMoreDetail'}
                                        isMandatory={somethingElseMoreDetailIsMandatory(
                                            formik.values.otherOptions.somethingElse,
                                        )}
                                        disabled={somethingElseMoreDetailDisabled(
                                            formik.values.otherOptions.somethingElse,
                                        )}
                                    />
                                </div>
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ButtonContainer nav>
                                    <Button type={'button'} testId={'backButton'} onClick={onBackClick} secondary>
                                        {t('translation:editableSectionNav.back')}
                                    </Button>
                                    <Button type={'button'} testId={'continueButton'} onClick={formik.submitForm}>
                                        {t('translation:editableSectionNav.continue')}
                                    </Button>
                                </ButtonContainer>
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <AlternativeNotification />
                            </Fieldset.Row>
                        </Fieldset>
                    </Form>
                )}
            </Formik>
        </View>
    );
};
