import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, FormikProps } from 'formik';
import { Button, ButtonContainer, Fieldset, Form, Paragraph, SectionHeading } from '@vwfs-bronson/bronson-react';
import { preventSubmit } from '@cp-shared-8/frontend-ui';
import { ContractDescriptionBO, formatAsNumber, numberFormatNoSeparators } from '@cp-uk/common';
import { textWithComponents } from 'utils';
import { View } from 'components/view/View';
import { ContractDescription } from 'components/contract-description/ContractDescription';
import { ValidatedInput } from 'components/validated-input/ValidatedInput';
import { ArrearsBalanceParagraph } from '../arrears-balance-paragraph/ArrearsBalanceParagraph';
import { AlternativeNotification } from '../alternative-notification/AlternativeNotification';
import { SummaryItem } from '../types';
import { buildInitialValues, buildSelections, buildSummaryItem, expenditureTotalled, incomeTotalled } from './helpers';
import { validationSchema } from './IncomeAndExpenditureValidation';
import { IncomeAndExpenditureFormValues, IncomeAndExpenditureSelections } from './types';

export type IncomeAndExpenditureViewProps = {
    contractDescription: ContractDescriptionBO;
    totalArrears: number | undefined;
    currentOptionTitle: string;
    currentSelections: IncomeAndExpenditureSelections | undefined;
    onBack: () => void;
    onContinue: (selections: IncomeAndExpenditureSelections, summaryItem: SummaryItem) => void;
};

export const IncomeAndExpenditureView: React.FC<IncomeAndExpenditureViewProps> = ({
    contractDescription,
    totalArrears,
    currentOptionTitle,
    currentSelections,
    onBack,
    onContinue,
}) => {
    const { t } = useTranslation('request-additional-help-v2-income-and-expenditure-view');

    // TODO: Analytics...

    const pound = '£';

    const totalFormatted = (total: number): string => {
        return Number.isNaN(total) ? t('money.validation.total') : formatAsNumber(total, numberFormatNoSeparators);
    };

    const initialValues = buildInitialValues(currentSelections);

    const onMoneyChange = (
        { target: { value: newValue, name: targetName } }: React.ChangeEvent<HTMLInputElement>,
        { values, setFieldValue }: FormikProps<IncomeAndExpenditureFormValues>,
    ): void => {
        const clonedValues: IncomeAndExpenditureFormValues = {
            income: { ...values.income },
            expenditure: { ...values.expenditure },
            disposableIncome: { ...values.disposableIncome },
        };

        const [containerName, inputName] = targetName.split('.');
        clonedValues[containerName][inputName] = newValue.replace(/[^0-9]/g, '');

        const { income, expenditure } = clonedValues;

        const incomeTotal = incomeTotalled(income);
        const expenditureTotal = expenditureTotalled(expenditure);
        const disposableIncomeTotal = incomeTotal - expenditureTotal;

        setFieldValue('income.total', totalFormatted(incomeTotal));
        setFieldValue('expenditure.total', totalFormatted(expenditureTotal));
        setFieldValue('disposableIncome.total', totalFormatted(disposableIncomeTotal));
    };

    const onPrintClick: () => void = () => {
        // TODO: Analytics...
        window.print();
    };

    const onBackClick = (): void => {
        // TODO: Analytics...
        onBack();
    };

    return (
        <View testId={'incomeAndExpenditureView'}>
            <SectionHeading level={2} testId={'viewHeader'}>
                {t('subHeading', { currentOptionTitle })}
            </SectionHeading>
            <ArrearsBalanceParagraph totalArrears={totalArrears} />
            <ContractDescription className={'u-mb'} contractDescription={contractDescription} />
            <Paragraph testId={'introductionParagraph'}>{textWithComponents(t, 'paragraphs.introduction')}</Paragraph>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema(t)}
                onSubmit={(values): void => {
                    const selections = buildSelections(values);

                    const summaryItem = buildSummaryItem(t, values);

                    onContinue(selections, summaryItem);
                }}
            >
                {(formik: FormikProps<IncomeAndExpenditureFormValues>): React.ReactNode => (
                    <Form className={'uk-request-additional-help-income-and-expenditure-form'} onSubmit={preventSubmit}>
                        <Fieldset>
                            <Fieldset.Row>
                                <Paragraph>{t('incomeAndExpenditure.income.subHeading')}</Paragraph>
                                <div className={'u-indent u-mb'}>
                                    {Object.keys(formik.values.income)
                                        .filter((key) => key !== 'total')
                                        .map((key) => (
                                            <ValidatedInput
                                                key={key}
                                                className={'input-money u-mb-xsmall'}
                                                label={t(`incomeAndExpenditure.income.${key}.label`)}
                                                name={`income.${key}`}
                                                testId={`income.${key}`}
                                                addonText={pound}
                                                reversed
                                                isMandatory
                                                handleChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                                    onMoneyChange(e, formik);
                                                }}
                                            />
                                        ))}
                                    <ValidatedInput
                                        className={'input-money u-mb-xsmall'}
                                        label={t('incomeAndExpenditure.income.total.label')}
                                        name={'income.total'}
                                        testId={'income.total'}
                                        addonText={pound}
                                        reversed
                                        disabled
                                    />
                                </div>
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <Paragraph>{t('incomeAndExpenditure.expenditure.subHeading')}</Paragraph>
                                <div className={'u-indent u-mb'}>
                                    {Object.keys(formik.values.expenditure)
                                        .filter((key) => key !== 'total')
                                        .map((key) => (
                                            <ValidatedInput
                                                key={key}
                                                className={'input-money u-mb-xsmall'}
                                                label={t(`incomeAndExpenditure.expenditure.${key}.label`)}
                                                name={`expenditure.${key}`}
                                                testId={`expenditure.${key}`}
                                                addonText={pound}
                                                reversed
                                                isMandatory
                                                handleChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                                    onMoneyChange(e, formik);
                                                }}
                                            />
                                        ))}
                                    <ValidatedInput
                                        className={'input-money u-mb-xsmall'}
                                        label={t('incomeAndExpenditure.expenditure.total.label')}
                                        name={'expenditure.total'}
                                        testId={'expenditure.total'}
                                        addonText={pound}
                                        reversed
                                        disabled
                                    />
                                </div>
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ValidatedInput
                                    className={'input-money u-mb-xsmall'}
                                    label={t('incomeAndExpenditure.disposableIncome.total.label')}
                                    name={'disposableIncome.total'}
                                    testId={'disposableIncome.total'}
                                    addonText={pound}
                                    reversed
                                    disabled
                                />
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <Paragraph className={'u-text-muted'}>{t('paragraphs.printOrSave')}</Paragraph>
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ButtonContainer nav>
                                    <Button
                                        type={'button'}
                                        testId={'printButton'}
                                        icon={'semantic-print'}
                                        iconReversed
                                        simple
                                        small
                                        link
                                        onClick={onPrintClick}
                                    >
                                        {t('translation:editableSectionNav.print')}
                                    </Button>
                                    <Button type={'button'} testId={'backButton'} onClick={onBackClick} secondary>
                                        {t('translation:editableSectionNav.back')}
                                    </Button>
                                    <Button type={'button'} testId={'continueButton'} onClick={formik.submitForm}>
                                        {t('translation:editableSectionNav.continue')}
                                    </Button>
                                </ButtonContainer>
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <AlternativeNotification />
                            </Fieldset.Row>
                        </Fieldset>
                    </Form>
                )}
            </Formik>
        </View>
    );
};
