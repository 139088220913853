import { TFunction } from 'i18next';
import { joinForSentence, quote } from 'utils';
import { SummaryItem, Views } from '../types';
import { OtherOptionsFormValues, OtherOptionsSelections } from './types';

export const buildInitialValues = (
    selections: OtherOptionsSelections | undefined,
): OtherOptionsFormValues => {
    if (selections === undefined) {
        return {
            otherOptions: {
                deferringFirstPaymentUpTo90Days: false,
                endingAgreementAndOptionsAvailable: false,
                somethingElse: false,
            },
            somethingElseMoreDetail: '',
        };
    }

    const {
        otherOptions: { deferringFirstPaymentUpTo90Days, endingAgreementAndOptionsAvailable, somethingElse },
        somethingElseMoreDetail,
    } = selections;

    return {
        otherOptions: {
            deferringFirstPaymentUpTo90Days,
            endingAgreementAndOptionsAvailable,
            somethingElse,
        },
        somethingElseMoreDetail,
    };
};

export const buildSelections = ({
    otherOptions,
    somethingElseMoreDetail,
}: OtherOptionsFormValues): OtherOptionsSelections => {
    const { deferringFirstPaymentUpTo90Days, endingAgreementAndOptionsAvailable, somethingElse } = otherOptions;

    return {
        otherOptions: {
            deferringFirstPaymentUpTo90Days,
            endingAgreementAndOptionsAvailable,
            somethingElse,
        },
        somethingElseMoreDetail,
    };
};

export const buildSummaryItem = (t: TFunction, { otherOptions }: OtherOptionsFormValues): SummaryItem => {
    const otherOptionsAsStringArray = Object.entries(otherOptions)
        .filter(([_, value]) => !!value)
        .map(([key, _]) => quote(t(`otherOptions.${key}.label`)));

    const otherOptionsAsString = joinForSentence(
        otherOptionsAsStringArray,
        () => t('summaryItem.commaWithSpace'),
        () => t('summaryItem.andWithSpaces'),
    );

    return {
        view: Views.otherOptions,
        heading: t('summaryItem.heading'),
        content: t('summaryItem.content', { otherOptions: otherOptionsAsString }),
    };
};

export const somethingElseMoreDetailDisabled = (otherOptionsSomethingElse: boolean): boolean => {
    return !otherOptionsSomethingElse;
};

export const somethingElseMoreDetailIsMandatory = (otherOptionsSomethingElse: boolean): boolean => {
    return otherOptionsSomethingElse;
};
